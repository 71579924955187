import React, { useEffect, useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import TagSelect from '../../../../components/TagSelect';
import Input from '../../../../components/Input';
import { map } from 'lodash';
import { object } from 'prop-types';

import * as productTypes from '../../../../redux/futures/product/types';
import productActions from '../../../../redux/futures/product/actions';
import { ConditionItem } from './ConditionItem';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function FilterConditionOrderv2(props) {
    const dispatch = useDispatch();
    const wrapperRef = useRef();
    const conditionRef = useRef();
    const { t } = useTranslation();

    const { openModalSaveCondition, dataConditions = {}, conditionFields = [], setDataCondition, removeCondition, isSave = true } = props;

    const [fieldSelected, setFieldSelected] = useState(null);
    const [showListFilter, setShowListFilter] = useState(false);

    const hideUrlPanelTrigger = () => {
        setShowListFilter(false);
    };

    const applyCondition = () => {
        let dataSelect = null;

        if (conditionRef && conditionRef.current) {
            dataSelect = conditionRef.current.getData();
            setDataCondition(dataSelect);
            setShowListFilter(false);
            setFieldSelected(null);
        }
    };

    return (
        <div className='block-condition-v2'>
            <div className='conditions'>
                {dataConditions.conditions.length > 0 &&
                    dataConditions.conditions.map((item, index) => {
                        return (
                            <div key={index} className='item-condition'>
                                <span className='name-condition'>{item.name}</span>
                                <span className='name-operator'>{item.operator.NAME}</span>
                                {item.fieldSelected && item.fieldSelected.value && (
                                    <span className='name-selected'>{item.fieldSelected.name} </span>
                                )}
                                {item.valueFilter && <span className='name-selected'>{item.valueFilter} </span>}
                                {item.multiSelected && item.multiSelected.length > 0 && (
                                    <div>
                                        {item.multiSelected.map((itemMul, indexMul) => {
                                            return (
                                                <span key={indexMul} className='item-multi-selected'>
                                                    {indexMul > 0 ? ', ' : ''}
                                                    <span className='name-item-multi-selected'>{itemMul.name}</span>
                                                </span>
                                            );
                                        })}
                                    </div>
                                )}
                                {item.tags && item.tags.length > 0 && (
                                    <div>
                                        {item.tags.map((itemTag, indexTag) => {
                                            return (
                                                <span key={indexTag} className='item-multi-selected'>
                                                    {indexTag > 0 ? ', ' : ''}
                                                    <span className='name-item-multi-selected'>{itemTag}</span>
                                                </span>
                                            );
                                        })}
                                    </div>
                                )}
                                {/* <div className='tag-delete'>
                                    <i className='ldicon-close' onClick={() => removeCondition(index)} />
                                </div> */}
                                <i className='new-ldicon-close-fill-2' onClick={() => removeCondition(index)} />
                            </div>
                        );
                    })}

                <div className={`add-item-conditions relative`}>
                    <div className='add-condition-title' onClick={() => setShowListFilter(!showListFilter)}>
                        <span className='title'>+ {t('CONDITIONS_FILTER.ADD_NEW_CONDITIONS')}</span>
                    </div>
                    {showListFilter ? (
                        // {false ? (
                        <>
                            <div className='background' onClick={hideUrlPanelTrigger}></div>
                            <div className='add-condition z-index1000'>
                                <div className='ladiui filter-box'>
                                    <div className='ladiui btn-group'>
                                        <div className='ladiui dropdown'>
                                            <button
                                                className='ladiui btn btn-outline-light dropdown-toggle select-dropdown'
                                                data-toggle='dropdown'
                                            >
                                                <i></i>
                                                <span className='ladiui dropdown-text'>{t('CONDITIONS_FILTER.SELECT_CUSTOM_FIELD')}</span>
                                            </button>
                                            <ul className='ladiui dropdown-menu list-condition'>
                                                {conditionFields.map((item, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            onClick={() => {
                                                                setFieldSelected(item);
                                                                // setShowListFilter(false);
                                                            }}
                                                        >
                                                            <a className='ladiui dropdown-item'>{item.name}</a>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    {fieldSelected && <ConditionItem ref={conditionRef} dataCondition={fieldSelected} />}
                                    <div className='ladiui footer-list-filter-condition flex-row'>
                                        <button
                                            type='button'
                                            onClick={hideUrlPanelTrigger}
                                            className='ladiui btn btn-secondary btn-sm mr-8'
                                        >
                                            {t('ACTIONS.CANCEL')}
                                        </button>
                                        <button type='button' className='ladiui btn btn-primary btn-sm' onClick={applyCondition}>
                                            {t('COMMON.APPLY')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>

                {isSave && dataConditions.conditions.length > 0 && (
                    <div className={`save-condition`}>
                        <div className='add-condition-title' onClick={() => openModalSaveCondition(true)}>
                            <i className='ldicon-save' />
                            <span className='title'>{t('CONDITIONS_FILTER.SAVE')}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default withTranslation()(FilterConditionOrderv2);
