import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';
import { map, includes, compact } from 'lodash';
import Dropdown from '../../../../components/Dropdown';
import bankHubActions from '../../../../redux/futures/bank_hub/actions';
import * as bankHubTypes from '../../../../redux/futures/bank_hub/types';

import produce from 'immer';

ModalOTP.propTypes = {
  onCancel: PropTypes.func,
};

export default function ModalOTP(props) {
  const {
    logo,
    onCancel,
    onOpenModalNotifyConnectSuccessfulApi,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bankRef = new Set();
  const bankHubReducer = useSelector((state) => state.bankHub);
  const [modalData, setModalData] = useState({

  });


  const loading = useMemo(() => {
    return bankHubReducer.loading || false;
  }, [bankHubReducer.loading]);

  const requestBankHub = useMemo(() => {
    return props.requestBankHub || {};
  }, [props.requestBankHub]);

  const onChangeModalInput = (event) => {
    setModalData(
      produce(modalData, (draft) => {
        draft[event.target.name] = event.target.value;
      })
    );
  };

  const handleContinue = () => {
    // validate + action api continue
    let errors = [];
    const inputsRef = compact(Array.from(bankRef));

    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);

    if (errors.length > 0) {
      const errorMessage = errors.join('<br/>');
      window.LadiUI.toastCustom('danger', '', errorMessage);
      return;
    }
    const data = {
      request_id: requestBankHub.request_id,
      otp: modalData.input_5
    }
    console.log(requestBankHub, 'data ', data);
    if (requestBankHub.action == bankHubTypes.REQUEST_OTP_DELETE) {
      dispatch(bankHubActions.submitOtpDelete(data));
    } else {
      dispatch(bankHubActions.submitOtpConnect(data));
    }
  }

  useEffect(() => {

    if (bankHubReducer.action === bankHubTypes.SUBMIT_OTP_CONNECT) {
      if (bankHubReducer.status) {
        onCancel();
        onOpenModalNotifyConnectSuccessfulApi()
        dispatch(bankHubActions.listBankHub({}));
      } else {
        window.LadiUI.showErrorMessage('Thông báo', bankHubReducer.message, 'OK');
      }
    }
    if (bankHubReducer.action === bankHubTypes.SUBMIT_OTP_DELETE) {
      if (bankHubReducer.status) {
        onCancel();
        window.LadiUI.toastCustom('success', '', bankHubReducer.message);
        dispatch(bankHubActions.listBankHub({}));
      } else {
        window.LadiUI.showErrorMessage('Thông báo', bankHubReducer.message, 'OK');
      }
    }


  }, [bankHubReducer]);

  const handleResendOTP = () => {
    console.log(' handler Res', requestBankHub)
    if (requestBankHub.action == bankHubTypes.REQUEST_OTP_DELETE) {
      dispatch(bankHubActions.requestOtpDelete({ bank_id: requestBankHub.id }, requestBankHub));
    } else {
      dispatch(bankHubActions.resendOtpConnect({ bank_id: requestBankHub.id }, requestBankHub));
    }
  }
  console.log('requestBankHub  OTP', requestBankHub);
  const isLoadingDisconnect = bankHubReducer.loading && (
    bankHubTypes.SUBMIT_OTP_CONNECT === bankHubReducer.waiting
    || bankHubTypes.SUBMIT_OTP_DELETE === bankHubReducer.waiting
    || bankHubTypes.RESEND_OTP_CONNECT === bankHubReducer.waiting
    || bankHubTypes.REQUEST_OTP_DELETE === bankHubReducer.waiting);
  return (
    <Modal
      id='modal-verify-otp'
      title={t('Xác thực OTP')}
      onCancel={onCancel}
      hasFooter={true}
      visible={true}
      width={600}
      logo={logo}
      bodyStyles={{ maxHeight: '350px' }}
      isCustomFooter={true}
      footer={
        <div className='ladiui-modal-footer'>
          <div className='ls-w-full ls-flex ls-justify-end'>
            <div></div>
            <button
              onClick={(e) => { handleContinue(); }}
              className={`ladiui btn btn-primary ${isLoadingDisconnect ? 'loader' : ''}`}
            >
              {t('Tiếp tục')}
            </button>
          </div>
        </div>
      }
    >
      <div className='content mt-0'>
        <React.Fragment>
          <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-20'>
            <div className='ls-row-item'>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-bg-layer-info ls-p-16 ls-gap-8 ls-rounded-8'>
                <div>
                  Mã <span className='ls-font-medium'>OTP</span> đã gửi về số điện thoại xác thực. Vui lòng điền mã OTP để xác nhận với LadiSales. Mã OTP sẽ hết hạn trong vòng 2 phút.
                </div>
              </div>
            </div>

            <div className='ls-row-item ls-grid ls-gap-16' style={{ '--column': '1fr' }}>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'>
                <label className='ladiui-label'>Nhập mã OTP</label>
                <div className='ls-relative ls-w-full'>
                  <Input
                    ref={(ref) => bankRef.add(ref)}
                    value={modalData?.input_5}
                    validationName={t('NOTIFICATION_BANKING.OTP_INPUT')}
                    name='input_5'
                    onChange={(event) => onChangeModalInput(event)}
                    validations={{ isRequired: true }}
                  />
                  <a className='ls-absolute ls-right-10 ls-top-12' onClick={handleResendOTP}>Gửi lại mã</a>
                </div>
              </div>
            </div>

            <div className='ls-row-item ls-grid ls-gap-16' style={{ '--column': '1fr' }}>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'>
                <label className='ladiui-label'>Trường hợp không nhận được mã OTP hoặc mã OTP hết hiệu lực, hãy chọn gửi lại mã.</label>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Modal>
  );
}
