import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

ModalNotifyConnectSuccessfulApi.propTypes = {
  onCancel: PropTypes.func,
};

export default function ModalNotifyConnectSuccessfulApi(props) {
  const {

    logo,
    onCancel
  } = props;

  const { t } = useTranslation();

  const handleAgree = () => {
    onCancel();
  }
  const requestBankHub = useMemo(() => {
    return props.requestBankHub || {};
  }, [props.requestBankHub]);

  console.log(' Modla Success requestBankHub = ', requestBankHub)
  return (
    <Modal
      id='modal-connect-success'
      title={t('Liên kết thành công')}
      onCancel={onCancel}
      hasFooter={true}
      visible={true}
      width={600}
      logo={logo}
      bodyStyles={{ maxHeight: '350px' }}
      isCustomFooter={true}
      footer={
        <div className='ladiui-modal-footer'>
          <div id='data-tooltip-guide' className='ladiui help-integrate'>
            <span className='tooltip-icon flex mr-10' data-tooltip-position='right'>
              {' '}
              <i className='ladi-icon icon-c-question' />
            </span>
            <span className='ls-text-secondary'>
              Tìm hiểu thêm
            </span>
          </div>
          <div className='ls-flex ls-justify-end'>
            <button
              onClick={handleAgree}
              className={`ladiui btn btn-primary ${props.isLoading ? 'loader' : ''}`}
            >
              {t('Đồng ý')}
            </button>
          </div>
        </div>
      }
    >
      <div className='content mt-0'>
        <React.Fragment>
          <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-20'>
            <div className='ls-row-item'>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-bg-layer-success ls-p-16 ls-gap-8 ls-rounded-8'>
                <div>
                  Liên kết MB qua API thành công. Thông tin của bạn
                </div>
              </div>
            </div>

            <div className='ls-row-item ls-grid ls-gap-20' style={{ '--column': '1fr' }}>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-16'>

                <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8 ls-min-w-160 ls-max-w-160'>
                    <i className='new-ldicon-bankcard ls-bg-icon-secondary'></i>
                    <div className='ls-text-secondary'>
                      Ngân hàng
                    </div>
                  </div>

                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                    <img src='https://w.ladicdn.com/ladiui/ladisales/logo-bank-tranfer.svg' className='ls-min-w-20 ls-max-w-20' />
                    <div className='ls-font-medium'>
                      Ngân hàng TMCP Quân Đội (MB)
                    </div>
                  </div>
                </div>

                <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8 ls-min-w-160 ls-max-w-160'>
                    <i className='new-ldicon-user-asign ls-bg-icon-secondary'></i>
                    <div className='ls-text-secondary'>
                      Chủ tài khoản
                    </div>
                  </div>

                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                    <div className='ls-font-medium'>
                      {requestBankHub ? requestBankHub.account_holder_name : ''}
                    </div>
                  </div>
                </div>

                <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8 ls-min-w-160 ls-max-w-160'>
                    <i className='new-ldicon-bankcard ls-bg-icon-secondary'></i>
                    <div className='ls-text-secondary'>
                      Số tài khoản
                    </div>
                  </div>

                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                    <div className='ls-font-medium'>
                      {requestBankHub ? requestBankHub.account_number : ''}
                    </div>
                  </div>
                </div>

                <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8 ls-min-w-160 ls-max-w-160'>
                    <i className='new-ldicon-userid ls-bg-icon-secondary'></i>
                    <div className='ls-text-secondary'>
                      Số CCCD/CMND
                    </div>
                  </div>

                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                    <div className='ls-font-medium'>
                      {requestBankHub ? requestBankHub.identification_number : ''}
                    </div>
                  </div>
                </div>

                <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8 ls-min-w-160 ls-max-w-160'>
                    <i className='new-ldicon-phone ls-bg-icon-secondary'></i>
                    <div className='ls-text-secondary'>
                      Số điện thoại
                    </div>
                  </div>

                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                    <div className='ls-font-medium'>
                      {requestBankHub ? requestBankHub.phone_number : ''}
                    </div>
                  </div>
                </div>

                <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8 ls-min-w-160 ls-max-w-160'>
                    <i className='new-ldicon-list ls-bg-icon-secondary'></i>
                    <div className='ls-text-secondary'>
                      Tên gợi nhớ
                    </div>
                  </div>

                  <div className='ls-flex ls-flex-row ls-justify-start ls-items-center ls-w-full ls-gap-8'>
                    <div className='ls-font-medium'>
                      {requestBankHub ? requestBankHub.label : ''}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Modal>
  );
}
