import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../helpers/BaseHelper';
import LadiPagination from './LadiPagination';
// eslint-disable-next-line max-lines-per-function
let CDN = 'https://w.ladicdn.com/';
function ModalAddVideo(props) {
    const {
        t,
        onClose,
        isShow,
        searchVariant,
        onFocusVariantInput,
        onChangeInputSearch,
        handleSearchListVideo,
        innerStyle,
        listVideos,
        setVideoDataUploaded,
        handleUploadVideo,
        handlePrevPage,
        handleNextPage,
        selectedPage,
        totalPageVideo,
    } = props;
    const wrapperRef = useRef();

    const [selectedVideoId, setSelectedVideoId] = useState('');
    const [selectedVideoName, setSelectedVideoName] = useState('');
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onClose(false);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const dispatch = useDispatch();

    const eventCancel = () => {
        onClose(false);
    };

    const handleSelectedVideo = (video) => {
        if (video.product_video_id) {
            setSelectedVideoId(video.product_video_id);
        }
        if (video.name) {
            setSelectedVideoName(video.name);
        }
    };

    const handleAddDataVideo = () => {
        if (selectedVideoId) {
            setVideoDataUploaded(selectedVideoId, selectedVideoName);
        }
    };

    const handleSearchVideo = (e) => {
        e.preventDefault();
        props.handleSearchListVideo(searchValue);
    };
    // const handleOpenUploadVideo = () => {
    //   handleUploadVideo();
    // };

    const onkeydownInputSearch = (event) => {
        if (event.key == 'Enter') {
            props.handleSearchListVideo(searchValue);
        }
    };

    const handlePrevPageBtn = (e) => {
        e.preventDefault();
        props.handlePrevPage();
    };

    const handleNextPageBtn = (e) => {
        e.preventDefault();
        props.handleNextPage();
    };

    return (
        <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-add-video'>
            <div className='ladiui modal-dialog modal-dialog-centered' style={{ width: '800px' }} ref={wrapperRef}>
                <div className='ladiui modal-content '>
                    <div className='ladiui modal-header '>
                        <h2 className='ladiui modal-title '>Chọn video từ thư viện</h2>
                        <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                            <i className='ladiui icon icon-ldp-close'></i>
                        </button>
                    </div>
                    <div className='ladiui modal-body'>
                        <div className='ladiui search-group'>
                            <input
                                className='ladiui search-field dropdown-toggle form-control'
                                value={searchValue}
                                placeholder={'Tìm kiếm video'}
                                data-toggle='dropdown'
                                aria-expanded='false'
                                onFocus={onFocusVariantInput}
                                onKeyDown={(e) => onkeydownInputSearch(e)}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <i className='ladiui icon icon-search' onClick={(e) => handleSearchVideo(e)}></i>
                        </div>
                        <div className='cursor-pointer' style={innerStyle}>
                            <a className='flex-center-left cursor-pointer my-24 pd-0'>
                                <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                                <div> {t('PRODUCTS.ADD_NEW')}</div>
                            </a>
                        </div>

                        <div className={`list-videos ${innerStyle ? 'mt-24' : ''}`}>
                            {listVideos &&
                                listVideos.length > 0 &&
                                listVideos.map((item, index) => {
                                    return (
                                        <div key={index} className='list-video-item'>
                                            <label className='flex'>
                                                <div className='video-info'>
                                                    <div>
                                                        <input
                                                            type='radio'
                                                            name='radio-video-item'
                                                            className='ladiui form-check-input mr-16'
                                                            onChange={() => handleSelectedVideo(item)}
                                                        />
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={
                                                                item.url_thumbnail
                                                                    ? `${CDN}${item.url_thumbnail}`
                                                                    : 'https://w.ladicdn.com/ladiui/icons/ldicon-image.svg'
                                                            }
                                                            alt=''
                                                        />
                                                    </div>
                                                    <span>{item.name}</span>
                                                </div>
                                                <div className='date-time'>
                                                    <span className='time-video'>
                                                        {item.duration ? baseHelper.msToTimeSeconds(item.duration) : '00:00:00'}
                                                    </span>
                                                    <span className='date'>{baseHelper.formatDateToStr(item.created_at)}</span>
                                                </div>
                                            </label>
                                        </div>
                                    );
                                })}
                            {listVideos && listVideos.length == 0 && (
                                <div className='text-center'>
                                    <span>Bài giảng không có video!</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='ladiui modal-footer-custom'>
                        {listVideos && listVideos.length > 0 && (
                            <div className='footer-next-page'>
                                <button
                                    type='button'
                                    className='mr-24'
                                    style={selectedPage == 1 ? { pointerEvents: 'none' } : {}}
                                    onClick={(e) => handlePrevPageBtn(e)}
                                >
                                    <i className='ldicon-chevron-left' />
                                </button>
                                <span>
                                    Trang {selectedPage} / {totalPageVideo}{' '}
                                </span>
                                <button
                                    type='button'
                                    className='ml-24'
                                    style={totalPageVideo <= selectedPage ? { pointerEvents: 'none' } : {}}
                                    onClick={(e) => handleNextPageBtn(e)}
                                >
                                    <i className='ldicon-chevron-right' />
                                </button>
                            </div>
                        )}
                        <div className='ladiui footer-modal-btn'>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-secondary bold'
                                onClick={eventCancel}
                            >
                                {t('ACTIONS.CANCEL')}
                            </button>

                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-primary bold ml-16'
                                onClick={() => handleAddDataVideo()}
                            >
                                {t('ACTIONS.DONE')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalAddVideo);
