import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';
import { map, includes, compact } from 'lodash';

import bankHubActions from '../../../../redux/futures/bank_hub/actions';
import * as bankHubTypes from '../../../../redux/futures/bank_hub/types';

import produce from 'immer';

ModalSettingRecieveNotificationBank.propTypes = {
  onCancel: PropTypes.func,
};

export default function ModalSettingRecieveNotificationBank(props) {
  const {
    logo,
    onCancel,
    onOpenModalOTP
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bankRef = new Set();

  const bankHubReducer = useSelector((state) => state.bankHub);

  const loading = useMemo(() => {
    console.log('bankHubReducer.loading ', bankHubReducer.loading)
    return bankHubReducer.loading || false;
  }, [bankHubReducer]);

  const [policyConfirm, setPolicyConfirm] = useState(false);
  const [allVerify, setAllVerify] = useState(false);

  const [notificationData, setNotificationData] = useState({

  });
  const timeoutRef = useRef(null);  // Create a ref for the timeout

  const onChangeBankDataUpdateInput = (event) => {
    console.log(' value = event.target.checked', event.target.checked);
    setPolicyConfirm(event.target.checked);
  };

  const onChangeNotificationInput = (event) => {
    setNotificationData(
      produce(notificationData, (draft) => {
        draft[event.target.name] = event.target.value;
      })
    );
  };
  useEffect(() => {
    if (notificationData.input_1 && notificationData.input_1 != '') {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        dispatch(bankHubActions.getBankAccountHolder({ account_number: notificationData.input_1 }));
      }, 1000); // 3 seconds
    }
  }, [notificationData.input_1]);

  useEffect(() => {
    let errors = [];
    const inputsRef = compact(Array.from(bankRef));

    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);

    if (errors.length == 0 && policyConfirm) {
      setAllVerify(true);
    } else {
      setAllVerify(false);
    }

  }, [notificationData, policyConfirm]);

  useEffect(() => {
    if (bankHubReducer.action === bankHubTypes.GET_BANK_ACCOUNT_HOLDER) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (bankHubReducer.status) {
        setNotificationData(
          produce(notificationData, (draft) => {
            draft['input_2'] = bankHubReducer.accountHolderName;
          })
        );
      } else {
        window.LadiUI.showErrorMessage('Thông báo', bankHubReducer.message, 'OK');
      }
    }

    if (bankHubReducer.action === bankHubTypes.CONNECT_BANK_HUB) {
      if (bankHubReducer.status) {
        const dataSubmit = {
          request_id: bankHubReducer.request_id,
          bank_id: bankHubReducer.bank_id,
          account_holder_name: notificationData.input_2,
          account_number: notificationData.input_1,
          identification_number: notificationData.input_3,
          phone_number: notificationData.input_4,
          label: notificationData.input_5,
          action: bankHubTypes.CONNECT_BANK_HUB
        }
        console.log(' onOpenModalOTPasd as ', dataSubmit);
        onOpenModalOTP(dataSubmit);
      } else {
        window.LadiUI.showErrorMessage('Thông báo', bankHubReducer.message, 'OK');
      }
    }


  }, [bankHubReducer]);

  const handleContinue = () => {
    // validate + action api continue
    let errors = [];
    const inputsRef = compact(Array.from(bankRef));

    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);

    if (errors.length > 0) {
      const errorMessage = errors.join('<br/>');
      window.LadiUI.toastCustom('danger', '', errorMessage);
      return;
    }

    if (!policyConfirm) {
      window.LadiUI.toastCustom('danger', '', 'Vui lòng xác nhận chính sách và điều khoản');
      return;
    }
    const data = {
      "account_holder_name": notificationData.input_2 || false,
      "account_number": notificationData.input_1 || false,
      "identification_number": notificationData.input_3 || false,
      "phone_number": notificationData.input_4 || false,
      "label": notificationData.input_5 || false,
    }
    console.log('connectBankHub , da', data);
    dispatch(bankHubActions.connectBankHub(data));

  }

  const isLoadingDisconnect = bankHubReducer.loading && bankHubTypes.CONNECT_BANK_HUB === bankHubReducer.waiting;
  console.log(' isLoadingDisconnect=  ', isLoadingDisconnect)
  return (
    <Modal
      id='modal-bank-pay-2'
      title={t('Kết nối nhận thông báo ngân hàng')}
      onCancel={onCancel}
      hasFooter={true}
      visible={true}
      width={800}
      logo={logo}
      bodyStyles={{ minHeight: '40vh' }}
      isCustomFooter={true}
      footer={
        <div className='ladiui-modal-footer'>
          <div id='data-tooltip-guide' className='ladiui help-integrate'>
            <span className='tooltip-icon flex mr-10' data-tooltip-position='right'>
              {' '}
              <i className='ladi-icon icon-c-question' />
            </span>
            <span>
              Hướng dẫn thiết lập phương thức thanh toán qua&nbsp;
              <a
                href='https://help.ladisales.vn/cai-dat-tai-khoan/phuong-thuc-thanh-toan/chuyen-khoan-qua-ngan-hang'
                target='_blank'
              >
                Ngân hàng
              </a>
            </span>
          </div>
          <div className='flex'>
            {!allVerify ?
              <button className='ladiui btn btn-secondary mr-12'>
                {t('Tiếp tục')}
              </button>
              :
              <button onClick={handleContinue} className={`ladiui btn btn-primary ${isLoadingDisconnect ? 'loader' : ''} mr-12`}>
                {t('Tiếp tục')}
              </button>
            }
          </div>
        </div>
      }
    >
      <div className='content mt-0'>
        <React.Fragment>
          <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-20'>
            <div className='ls-row-item'>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-bg-layer-info ls-p-16 ls-gap-8 ls-rounded-8'>
                <div className='ls-text-black ls-font-medium ls-text-16'>
                  Hướng dẫn
                </div>
                <div className='ls-text-secondary'>
                  Điền thông tin số tài khoản ngân hàng MB của bạn. Nếu chưa có tài khoản, vui lòng làm theo hướng dẫn sau:
                </div>
                <div className=''>
                  <a href='https://help.ladipage.vn' target='_blank'>Hướng dẫn mở tài khoản ngân hàng MB cá nhân online</a>
                </div>
              </div>
            </div>
            <div className='ls-row-item ls-grid ls-gap-16' style={{ '--column': '1fr 1fr' }}>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'>
                <label className='ladiui-label'>Số tài khoản <span className='ls-text-danger'>*</span></label>
                <label className='ladiui-label ls-text-11'>Điền chính xác số tài khoản ngân hàng MB</label>
                <Input
                  ref={(ref) => bankRef.add(ref)}
                  value={notificationData?.input_1}
                  validationName={t('NOTIFICATION_BANKING.BANK_NUMBER')}
                  name='input_1'
                  onChange={(event) => onChangeNotificationInput(event)}
                  validations={{ isRequired: true }}
                  placeholder='Nhập số tài khoản'
                />
              </div>

              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'>
                <label className='ladiui-label'>Tên chủ tài khoản <span className='ls-text-danger'>*</span></label>
                <label className='ladiui-label ls-text-11'>Tên chủ tài khoản sẽ tự động điền theo số tài khoản</label>
                <Input
                  ref={(ref) => bankRef.add(ref)}
                  value={notificationData?.input_2}
                  validationName={t('NOTIFICATION_BANKING.BANK_NAME')}
                  name='input_2'
                  onChange={(event) => onChangeNotificationInput(event)}
                  validations={{ isRequired: false }}
                  disabled={true}
                  className='ls-bg-background-02'
                />
              </div>
            </div>

            <div className='ls-row-item ls-grid ls-gap-16' style={{ '--column': '1fr 1fr' }}>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'>
                <label className='ladiui-label'>Số CCCD/CMND <span className='ls-text-danger'>*</span></label>
                <label className='ladiui-label ls-text-11'>Số CCCD/CMND đã dùng để đăng ký tài khoản trên</label>
                <Input
                  ref={(ref) => bankRef.add(ref)}
                  value={notificationData?.input_3}
                  validationName={t('NOTIFICATION_BANKING.IDENTITY_NUMBER')}
                  name='input_3'
                  onChange={(event) => onChangeNotificationInput(event)}
                  validations={{ isRequired: true }}
                  placeholder='Nhập CCCD/CMND'
                />
              </div>

              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'>
                <label className='ladiui-label'>Số điện thoại <span className='ls-text-danger'>*</span></label>
                <label className='ladiui-label ls-text-11'>Số điện thoại đã dùng để đăng ký với số tài khoản trên</label>
                <Input
                  ref={(ref) => bankRef.add(ref)}
                  value={notificationData?.input_4}
                  validationName={t('NOTIFICATION_BANKING.PHONE_NUMBER')}
                  name='input_4'
                  onChange={(event) => onChangeNotificationInput(event)}
                  validations={{ isRequired: true }}
                  placeholder='Nhập số điện thoại'
                />
              </div>
            </div>

            <div className='ls-row-item ls-grid ls-gap-16' style={{ '--column': '1fr' }}>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'>
                <label className='ladiui-label'>Tên gợi nhớ</label>
                <Input
                  ref={(ref) => bankRef.add(ref)}
                  value={notificationData?.input_5}
                  validationName={t('NOTIFICATION_BANKING.LB_METHOD_NAME')}
                  name='input_5'
                  onChange={(event) => onChangeNotificationInput(event)}
                  validations={{ isRequired: false }}
                  placeholder='Nhập tên gợi nhớ'
                />
              </div>
            </div>

            <div className='ls-row-item ls-grid ls-gap-16' style={{ '--column': '1fr' }}>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'>
                <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-bg-layer-warning-hover ls-p-16 ls-gap-8 ls-rounded-8'>
                  <div className='flex'>
                    <input
                      type='checkbox'
                      className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                      name='autoConfirmPayment'
                      onChange={(event) => onChangeBankDataUpdateInput(event)}
                      checked={policyConfirm}
                    />
                    <span className=''>Tôi đã đọc và đồng ý với <a
                      href='https://ladipage.vn/dieukhoandichvu#BANK'
                      target='_blank'
                    >
                      Chính sách & điều khoản sử dụng dịch vụ
                    </a> trên</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </React.Fragment>
      </div>
    </Modal>
  );
}
