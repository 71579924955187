import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import TagSelect from '../../../../components/TagSelect';
import Input from '../../../../components/Input';
import Tag from '../../../../components/Tag';

import * as productTypes from '../../../../redux/futures/product/types';
import productActions from '../../../../redux/futures/product/actions';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
export const ConditionItem = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const tagRef = useRef();
    const { t } = useTranslation();

    const productReducer = useSelector((state) => state.product);
    const storeReducer = useSelector((state) => state.store);
    const orderTag = useSelector((state) => state.orderTag);
    const productTagReducer = useSelector((state) => state.productTag);
    const storePageReducer = useSelector((state) => state.storePage);
    const categoryReducer = useSelector((state) => state.productCategory);

    const products = useMemo(() => {
        return productReducer.products || [];
    }, [productReducer.products]);

    const allTags = useMemo(() => {
        return orderTag.allTags || [];
    }, [orderTag.allTags]);

    const allProductTags = useMemo(() => {
        return productTagReducer.allTags || [];
    }, [productTagReducer.allTags]);

    const allCategories = useMemo(() => {
        return categoryReducer.listSelects || [];
    }, [categoryReducer.listSelects]);

    const allStores = useMemo(() => {
        return storePageReducer.store_pages || [];
    }, [storePageReducer.store_pages]);

    const staff = useMemo(() => {
        return storeReducer.userInfo.staffs || [];
    }, [storeReducer.userInfo.staffs]);

    const { dataCondition = {} } = props;

    const [operator, setOperator] = useState(appConfig.OPERATORS.EQUAL);
    const [fieldSelected, setField] = useState({});
    const [valueFilter, setValueFilter] = useState('');

    const [multiSelected, setMultiSelected] = useState([]);

    const [data, setData] = useState(dataCondition);

    const handleSearchProduct = (valueSearch) => {
        dispatch(productActions.search(valueSearch));
    };

    useEffect(() => {
        if (dataCondition.type == appConfig.CONDITION_TYPE.MULTISELECT_SEARCH) {
            if (dataCondition.value == 'product_ids') {
                handleSearchProduct();
            }
        }

        setData(dataCondition);
        setOperator(dataCondition.operators[0]);
        setField({});
        setMultiSelected([]);
    }, [dataCondition]);

    useEffect(() => {
        if (dataCondition.value == 'product_ids') {
            setData((pre) => {
                let tg = { ...pre };
                tg.fields = products.map((item) => {
                    return {
                        name: item.name,
                        value: item.product_id,
                    };
                });
                return tg;
            });
        }
    }, [products, dataCondition]);

    useEffect(() => {
        if (dataCondition.value == 'tags') {
            setData((pre) => {
                let tg = { ...pre };
                tg.fields = allTags.map((item) => {
                    return {
                        name: item.name,
                        value: item.order_tag_id,
                    };
                });
                return tg;
            });
        }
    }, [allTags, dataCondition]);

    // useEffect(() => {
    //     if (dataCondition.value == 'tags') {
    //         setData((pre) => {
    //             let tg = { ...pre };
    //             tg.fields = allProductTags.map((item) => {
    //                 return {
    //                     name: item.name,
    //                     value: item.product_tag_id,
    //                 };
    //             });
    //             return tg;
    //         });
    //     }
    // }, [allProductTags, dataCondition]);

    useEffect(() => {
        if (dataCondition.value == 'product_category') {
            setData((pre) => {
                let tg = { ...pre };
                tg.fields = allCategories.map((item) => {
                    return {
                        name: item.name,
                        value: item.product_category_id,
                    };
                });
                return tg;
            });
        }
    }, [allCategories, dataCondition]);

    useEffect(() => {
        if (dataCondition.value == 'creator' || dataCondition.value == 'staff') {
            setData((pre) => {
                let tg = { ...pre };
                tg.fields = staff.map((item) => {
                    return {
                        name: item.name,
                        value: item.ladi_uid,
                    };
                });
                if (dataCondition.value == 'staff') {
                    tg.fields.unshift({
                        name: t('ORDERS.ASSIGN_NULL'),
                        value: 'ASSIGN_NULL',
                    });
                }
                return tg;
            });
        }
    }, [staff, dataCondition]);

    useImperativeHandle(ref, () => ({
        getData: () => {
            let tags = [];
            if (tagRef && tagRef.current) tags = tagRef.current.getData();

            // let values = [];

            return {
                // field: data.value,
                operator,
                // values,

                fieldSelected,
                tags,
                multiSelected,
                valueFilter,
                value: data.value,
                name: data.name,
            };
        },
    }));

    return (
        <div className='condition-item'>
            {data.type == appConfig.CONDITION_TYPE.SELECTED && (
                <div className='ladiui'>
                    <div className='ladiui dropdown'>
                        <button className='ladiui btn btn-outline-light dropdown-toggle select-dropdown' data-toggle='dropdown'>
                            <span className='ladiui dropdown-text'>{operator.NAME}</span>
                        </button>
                        <ul className='ladiui dropdown-menu'>
                            {data.operators.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => {
                                        setOperator(item);
                                    }}
                                >
                                    <span className='ladiui dropdown-item'>{item.NAME}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='ladiui btn-group mt-12'>
                        <div className='ladiui dropdown'>
                            <button className='ladiui btn btn-outline-light dropdown-toggle select-dropdown' data-toggle='dropdown'>
                                <i className='ladiui icon'></i>
                                <span className='ladiui dropdown-text'>{fieldSelected.name || t('CONDITIONS_FILTER.SELECT_VALUE')}</span>
                            </button>
                            <ul className='ladiui dropdown-menu'>
                                {data.fields.map((item, index) => (
                                    <li
                                        key={index}
                                        onClick={() => {
                                            setField(item);
                                        }}
                                    >
                                        <span className='ladiui dropdown-item'>{item.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            {data.type == appConfig.CONDITION_TYPE.INPUT && (
                <div className='ladiui'>
                    <div className='ladiui dropdown'>
                        <button className='ladiui btn btn-outline-light dropdown-toggle select-dropdown' data-toggle='dropdown'>
                            {/* <i className='ladiui icon'></i> */}
                            <span className='ladiui dropdown-text'>{operator.NAME}</span>
                        </button>
                        <ul className='ladiui dropdown-menu'>
                            {data.operators.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => {
                                        setOperator(item);
                                    }}
                                >
                                    <span className='ladiui dropdown-item'>{item.NAME}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='ladiui btn-group mt-12'>
                        <div className='ladiui dropdown'>
                            <Input
                                value={valueFilter ? valueFilter : ''}
                                onChange={(e) => setValueFilter(e.target.value)}
                                className='w-250'
                            />

                            {/* <Tag
                                hideTitle={true}
                                tabIndex={6}
                                hideMenuAll={true}
                                ref={tagRef}
                                id='utm-page'
                                allTags={[]}
                                className={'input-tag m-0'}
                                placeholderInput={t('ORDERS.PLACEHOLDER_ENTER')}
                            /> */}
                        </div>
                    </div>
                </div>
            )}
            {data.type == appConfig.CONDITION_TYPE.MULTISELECT && (
                <div className='ladiui'>
                    <div className='ladiui dropdown'>
                        <button className='ladiui btn btn-outline-light dropdown-toggle select-dropdown' data-toggle='dropdown'>
                            {/* <i className='ladiui icon'></i> */}
                            <span className='ladiui dropdown-text'>{operator.NAME}</span>
                        </button>
                        <ul className='ladiui dropdown-menu'>
                            {data.operators.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => {
                                        setOperator(item);
                                    }}
                                >
                                    <span className='ladiui dropdown-item'>{item.NAME}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='ladiui btn-group mt-12'>
                        <div className='ladiui dropdown'>
                            <TagSelect
                                allTags={data.fields}
                                selectedTags={multiSelected}
                                setSelected={setMultiSelected}
                                title={data.name}
                                className={'w-250 m-0'}
                            />
                        </div>
                    </div>
                </div>
            )}
            {data.type == appConfig.CONDITION_TYPE.MULTISELECT_SEARCH && (
                <div className='ladiui'>
                    <div className='ladiui dropdown'>
                        <button className='ladiui btn btn-outline-light dropdown-toggle select-dropdown' data-toggle='dropdown'>
                            {/* <i className='ladiui icon'></i> */}
                            <span className='ladiui dropdown-text'>{operator.NAME}</span>
                        </button>
                        <ul className='ladiui dropdown-menu'>
                            {data.operators.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => {
                                        setOperator(item);
                                    }}
                                >
                                    <span className='ladiui dropdown-item'>{item.NAME}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='ladiui btn-group mt-12'>
                        <div className='ladiui dropdown'>
                            <TagSelect
                                _key='product_id'
                                allTags={data.fields}
                                selectedTags={multiSelected}
                                setSelected={setMultiSelected}
                                title={data.name}
                                className={'w-250 m-0'}
                                isSearchApi={true}
                                handleCallApi={handleSearchProduct}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});
